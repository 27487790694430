import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'react-toastify/dist/ReactToastify.css';

import '@fontsource/alice'; // Defaults to weight 400
import '@fontsource/alice/400.css'; // Specify weight

import { Analytics } from '@vercel/analytics/react';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/dist/shared/lib/router/router';
import Head from 'next/head';
import { ColorModeScript } from 'nextjs-color-mode';
import React from 'react';

import ReactModal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalStyle } from 'components/GlobalStyles';
import WhatsappButton from 'components/WhatsappButton';
import { AuthProvider } from 'contexts/AuthContext';
import { CartProvider } from 'contexts/CartContext';
import { EventoProvider } from 'contexts/EventContext';
import { LoadProvider } from 'contexts/Loader';
import UTMHandler from 'utils/GoogleEvents/UTMHandler';
import UTMLinkHandler from 'utils/GoogleEvents/UTMLinkHandler';

function MyApp({ Component, pageProps }: AppProps) {
  ReactModal.setAppElement('#__next');

  const client = new QueryClient();

  return (
    <>
      <Head>
        <meta name="facebook-domain-verification" content="i4ax6p8qz5y107adk2p55w70sn5v82" />
        
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link rel="icon" type="image/svg" href="/favicon.svg" />

        <link rel="apple-touch-icon" sizes="280x280" href="favicon.svg" />
        <link rel="icon" type="image/svg" sizes="39x39" href="favicon.svg" />
        <link rel="icon" type="image/svg" sizes="16x16" href="favicon.svg" />

        <link href="https://fonts.googleapis.com/css2?family=Rochester&display=swap" rel="stylesheet" />
      </Head>
      <ColorModeScript />
      <GlobalStyle />
      <SessionProvider session={pageProps.session}>
        <QueryClientProvider client={client}>
          <LoadProvider>
            <AuthProvider>
              <EventoProvider>
                <CartProvider>
                  {/* <VendorScripts /> */}
                  <Analytics />
                  <Component {...pageProps} />
                  {/*<WhatsappButton />*/}

                  <UTMHandler />
                  <UTMLinkHandler />
                </CartProvider>
              </EventoProvider>
            </AuthProvider>
          </LoadProvider>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
